"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouteResolver = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _resolveChain = require("./resolveChain");

/**
 * Object that resolves given URL to a Route.
 */
class RouteResolver {
  constructor(link) {
    (0, _defineProperty2.default)(this, "link", void 0);
    this.link = link;
  }

  resolve(location) {
    return (0, _resolveChain.resolveChain)(this.link, location, {
      pathname: "/",
      state: null
    });
  }

}

exports.RouteResolver = RouteResolver;