"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BuilderLink = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classPrivateFieldSet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldSet"));

var _classPrivateFieldGet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldGet"));

var _RouteResolver = require("../RouteResolver");

var _resolveChain = require("../RouteResolver/resolveChain");

var _state = new WeakMap();

/**
 * Link between parent and child builders.
 */
class BuilderLink {
  /**
   * Registered current builder.
   */
  constructor(options) {
    (0, _defineProperty2.default)(this, "composer", void 0);
    (0, _defineProperty2.default)(this, "resolver", void 0);

    _state.set(this, {
      writable: true,
      value: {
        state: "unattached"
      }
    });

    (0, _defineProperty2.default)(this, "currentBuilder", undefined);
    (0, _defineProperty2.default)(this, "segmentDecomposer", void 0);
    this.composer = options.composer;
    this.resolver = new _RouteResolver.RouteResolver(this);
  }
  /**
   * Attach this link to a parent.
   */


  attachToParent(parentLink, segmentGetter) {
    if ((0, _classPrivateFieldGet2.default)(this, _state).state !== "unattached") {
      throw new Error("A builder cannot be attached more than once.");
    }

    (0, _classPrivateFieldSet2.default)(this, _state, {
      state: "attached",
      parentLink,
      segmentGetter
    });
    this.resolver = parentLink.resolver;
  }
  /**
   * Collect pairs of link and segment between its parent.
   */


  collectUpToTop() {
    const result = []; // eslint-disable-next-line @typescript-eslint/no-this-alias

    let current = this;
    let parent = current.getParentLinkAndSegmentGetter();

    while (parent !== undefined) {
      result.push({
        link: parent[1],
        segmentGetter: parent[0]
      });
      current = parent[1];
      parent = current.getParentLinkAndSegmentGetter();
    }

    return result;
  }

  composeFromTop(defaultRoot, match) {
    const links = this.collectUpToTop().reverse();
    return links.reduce((loc, {
      link,
      segmentGetter: segment
    }) => {
      return link.composer.compose(loc, segment(match));
    }, defaultRoot);
  }
  /**
   * Returns the topmost and uninherited link.
   */


  getAttachmentRoot() {
    const ls = this.collectUpToTop();
    const top = ls.length === 0 ? this : ls[ls.length - 1].link;
    return top;
  }

  getParentLinkAndSegmentGetter() {
    const state = (0, _classPrivateFieldGet2.default)(this, _state);

    if (state.state === "attached") {
      return [state.segmentGetter, state.parentLink];
    }

    return undefined;
  }

  getBuilderLink() {
    return this;
  }

  register(builder, resolveSegment) {
    this.currentBuilder = builder;
    this.segmentDecomposer = (0, _resolveChain.createSegmentDecomposer)(this, resolveSegment);
  }
  /**
   * Create a new BuilderLink which inherits current link.
   */


  inherit() {
    return this;
  }

}

exports.BuilderLink = BuilderLink;