"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocation = void 0;

var _react = require("react");

var _LocationContext = require("../contexts/LocationContext");

/**
 * Returns the current location.
 * Throws an error if there is no Rocon root above.
 */
const useLocation = () => {
  const location = (0, _react.useContext)(_LocationContext.LocationContext);

  if (location === undefined) {
    throw new Error("No location found. Please make sure you have placed RoconRoot above.");
  }

  return location;
};

exports.useLocation = useLocation;