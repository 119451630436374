"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConstRouteRecord = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _RouteRecordBase = require("./RouteRecordBase");

/**
 * Object for fixed-location route record.
 */
class ConstRouteRecord extends _RouteRecordBase.RouteRecordBase {
  constructor(parent, location, action) {
    super(parent.getBuilderLink(), action, () => this.location);
    (0, _defineProperty2.default)(this, "location", void 0);
    this.location = location;
  }

}

exports.ConstRouteRecord = ConstRouteRecord;