"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConstLocationComposer = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

/**
 * LocationComposer that composes to a constant location.
 */
class ConstLocationComposer {
  constructor(location) {
    (0, _defineProperty2.default)(this, "location", void 0);
    this.location = location;
  }

  isLeaf() {
    return false;
  }

  compose() {
    return this.location;
  }

  decompose(base) {
    return [{
      leaf: false,
      segment: undefined,
      nextLocation: base
    }];
  }

}

exports.ConstLocationComposer = ConstLocationComposer;