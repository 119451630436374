"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SingleRouteAbstractBuilder = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _getRouteRecordLocation = require("../RouteRecord/getRouteRecordLocation");

class SingleRouteAbstractBuilder {
  constructor() {
    (0, _defineProperty2.default)(this, "attach", void 0);
    Object.defineProperty(this, "attach", {
      configurable: true,

      value( // eslint-disable-next-line @typescript-eslint/no-explicit-any
      builder) {
        return this.getRoute().attach(builder);
      }

    });
  }

  get route() {
    return this.getRoute();
  }
  /**
   * Attach a given route builder to the current route.
   */


  // defined in the constructor

  /**
   * Get the location of this route.
   */
  getLocation(match) {
    return (0, _getRouteRecordLocation.getRouteRecordLocation)(this.getRoute(), match);
  }

}

exports.SingleRouteAbstractBuilder = SingleRouteAbstractBuilder;