"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUndefinedOr = exports.isNumber = exports.isStringOrUndefined = exports.isString = void 0;

/**
 * Type of function which checks whether given value is of type R.
 */
const isString = value => typeof value === "string";

exports.isString = isString;

const isStringOrUndefined = value => typeof value === "string" || value === undefined;

exports.isStringOrUndefined = isStringOrUndefined;

const isNumber = value => typeof value === "number";
/**
 * Convert a given validator to another validator that also accepts undefined.
 */


exports.isNumber = isNumber;

const isUndefinedOr = validator => value => value === undefined || validator(value);

exports.isUndefinedOr = isUndefinedOr;