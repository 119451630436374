"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RoutePathResolver = void 0;

var _classPrivateFieldGet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldGet"));

var _classPrivateFieldSet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldSet"));

var _resolver = new WeakMap();

class RoutePathResolver {
  static getFromBuilder(builder) {
    return new RoutePathResolver(builder.getBuilderLink().resolver);
  }

  constructor(resolver) {
    _resolver.set(this, {
      writable: true,
      value: void 0
    });

    (0, _classPrivateFieldSet2.default)(this, _resolver, resolver);
  }

  resolve(location) {
    return (0, _classPrivateFieldGet2.default)(this, _resolver).resolve(location).filter(isResultWithAction);
  }
  /**
   * Resolve given location and return the result of running associated action.
   * If multiple locations are resolved, the first one is used.
   */


  resolveAction(location) {
    const res = this.resolve(location)[0];

    if (res === undefined) {
      return undefined;
    }

    const result = res.route.action(res.match);
    return {
      route: res.route,
      result
    };
  }

}

exports.RoutePathResolver = RoutePathResolver;

function isResultWithAction(res) {
  return !!res.route.action;
}