"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useNavigate = void 0;

var _react = require("react");

var _getRouteRecordLocation = require("../../builder/RouteRecord/getRouteRecordLocation");

var _HistoryContext = require("../contexts/HistoryContext");

var _RouteContext = require("../contexts/RouteContext");

var _getNavigationBaseLocation = require("../logic/getNavigationBaseLocation");

const cache = new WeakMap();
/**
 * Returns the navigate function.
 */

const useNavigate = () => {
  const historyContextObject = (0, _react.useContext)(_HistoryContext.HistoryContext);
  const parentRoute = (0, _react.useContext)(_RouteContext.RouteContext);

  if (historyContextObject === undefined) {
    throw new Error("No history found in the context. Please make sure you have placed RoconRoot above.");
  }

  const {
    history
  } = historyContextObject;
  const navigate = (0, _react.useMemo)(() => {
    // create navigate function.
    const push = (route, match) => {
      const baseLocation = (0, _getNavigationBaseLocation.getNavigationBaseLocation)(parentRoute, route);
      history.push((0, _getRouteRecordLocation.getRouteRecordLocation)(route, match || {}, baseLocation));
    };

    const replace = (route, match) => {
      const baseLocation = (0, _getNavigationBaseLocation.getNavigationBaseLocation)(parentRoute, route);
      history.replace((0, _getRouteRecordLocation.getRouteRecordLocation)(route, match || {}, baseLocation));
    };

    return Object.assign(push, {
      push,
      replace
    });
  }, [history, parentRoute]);
  return navigate;
};

exports.useNavigate = useNavigate;