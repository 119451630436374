"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRouteRecordLocation = getRouteRecordLocation;

var _symbols = require("../symbols");

const defaultRoot = {
  pathname: "/",
  state: null
};

function getRouteRecordLocation(routeRecord, match, root = defaultRoot) {
  const parentLocation = routeRecord[_symbols.routeRecordParentKey].composeFromTop(root, match);

  return routeRecord[_symbols.routeRecordParentKey].composer.compose(parentLocation, routeRecord[_symbols.routeRecordSegmentGetterKey](match));
}