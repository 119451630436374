"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHistory = void 0;

var _react = require("react");

var _HistoryContext = require("../contexts/HistoryContext");

/**
 * Reads the history object from the history context.
 * Throws an error if there is no history in context.
 */
const useHistory = () => {
  const history = (0, _react.useContext)(_HistoryContext.HistoryContext);

  if (history === undefined) {
    throw new Error("No history found in the context. Please make sure you have placed RoconRoot above.");
  }

  return history.history;
};

exports.useHistory = useHistory;