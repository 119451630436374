"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.composePath = composePath;

function composePath(base, segment) {
  const b = base.endsWith("/");
  const s = segment.startsWith("/");

  if (b && s) {
    return base + segment.slice(1);
  }

  if (!b && !s) {
    return base + "/" + segment;
  }

  return base + segment;
}