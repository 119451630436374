"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PathLocationComposer = void 0;

var _composePath = require("./composePath");

var _decomposePath = require("./decomposePath");

class PathLocationComposer {
  isLeaf(location) {
    return location.pathname === "/";
  }

  compose(base, segment) {
    if (segment === undefined) {
      return base;
    }

    return { ...base,
      pathname: (0, _composePath.composePath)(base.pathname, segment)
    };
  }

  decompose(location) {
    const {
      pathname
    } = location;
    const s = (0, _decomposePath.decomposePath)(pathname);
    const current = {
      leaf: true,
      segment: undefined,
      nextLocation: location
    };

    if (s === undefined) {
      return [current];
    }

    const [segment, next] = s;
    const nextLocation = { ...location,
      pathname: next || "/"
    };
    return [current, {
      leaf: false,
      segment,
      nextLocation: nextLocation
    }];
  }

}

exports.PathLocationComposer = PathLocationComposer;