"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RootRouteBuilder = void 0;

var _classPrivateFieldGet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldGet"));

var _classPrivateFieldSet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldSet"));

var _BuilderLink = require("../../core/BuilderLink");

var _ConstLocationComposer = require("../composers/ConstLocationComposer");

var _ConstRouteRecord = require("../RouteRecord/ConstRouteRecord");

var _SingleRouteAbstractBuilder = require("../SingleRouteAbstractBuilder");

var _fillOptions = require("./fillOptions");

var _root = new WeakMap();

var _route = new WeakMap();

var _link = new WeakMap();

class RootRouteBuilder extends _SingleRouteAbstractBuilder.SingleRouteAbstractBuilder {
  static init(options = {}) {
    (0, _fillOptions.fillOptions)(options);
    const link = new _BuilderLink.BuilderLink({
      composer: new _ConstLocationComposer.ConstLocationComposer(options.root)
    });
    return new RootRouteBuilder(link, options.root);
  }

  constructor(link, root) {
    super();

    _root.set(this, {
      writable: true,
      value: void 0
    });

    _route.set(this, {
      writable: true,
      value: void 0
    });

    _link.set(this, {
      writable: true,
      value: void 0
    });

    (0, _classPrivateFieldSet2.default)(this, _link, link);
    (0, _classPrivateFieldSet2.default)(this, _root, root);
    (0, _classPrivateFieldSet2.default)(this, _route, new _ConstRouteRecord.ConstRouteRecord(this, root, undefined));
    link.register(this, () => {
      const route = (0, _classPrivateFieldGet2.default)(this, _route);
      return {
        type: "normal",
        value: route,
        link: route.getAttachedBuilderLink()
      };
    });
  }

  action(action) {
    const root = (0, _classPrivateFieldGet2.default)(this, _root);
    const result = new RootRouteBuilder((0, _classPrivateFieldGet2.default)(this, _link).inherit(), root);
    (0, _classPrivateFieldSet2.default)(result, _route, new _ConstRouteRecord.ConstRouteRecord(result, root, action));
    return result;
  }

  getRoute() {
    return (0, _classPrivateFieldGet2.default)(this, _route);
  }

  getBuilderLink() {
    return (0, _classPrivateFieldGet2.default)(this, _link);
  }

}

exports.RootRouteBuilder = RootRouteBuilder;