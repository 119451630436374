"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchLocationComposer = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

class SearchLocationComposer {
  constructor(key, optional) {
    (0, _defineProperty2.default)(this, "key", void 0);
    (0, _defineProperty2.default)(this, "optional", void 0);
    this.key = key;
    this.optional = optional;
  }

  isLeaf(location) {
    return !location.search;
  }

  compose(base, segment) {
    const params = new URLSearchParams(base.search);

    if (segment !== undefined) {
      params.set(this.key, segment);
    }

    const searchString = params.toString();
    return { ...base,
      search: searchString ? "?" + searchString : searchString
    };
  }

  decompose(location) {
    const {
      search
    } = location;
    const params = new URLSearchParams(search);
    const value = params.get(this.key);

    if (value === null) {
      if (this.optional) {
        return [{
          leaf: false,
          segment: undefined,
          nextLocation: location
        }];
      } else {
        return [];
      }
    }

    params.delete(this.key);
    const searchString = params.toString();
    const nextLocation = { ...location,
      search: searchString ? "?" + searchString : searchString
    };
    return [{
      leaf: false,
      // string is always valid as segment but TS does not allow it
      segment: value,
      nextLocation: nextLocation
    }];
  }

}

exports.SearchLocationComposer = SearchLocationComposer;