"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StateLocationComposer = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

class StateLocationComposer {
  constructor(key, validator, optional) {
    (0, _defineProperty2.default)(this, "key", void 0);
    (0, _defineProperty2.default)(this, "optional", void 0);
    (0, _defineProperty2.default)(this, "validator", void 0);
    this.key = key;
    this.optional = optional;
    this.validator = validator;
  }

  isLeaf(location) {
    var _location$state;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ((_location$state = location.state) === null || _location$state === void 0 ? void 0 : _location$state[this.key]) === undefined;
  }

  compose(base, segment) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newState = { ...base.state,
      [this.key]: segment
    };
    return { ...base,
      state: newState
    };
  }

  decompose(location) {
    const {
      state
    } = location; // eslint-disable-next-line @typescript-eslint/no-explicit-any

    const value = state === null || state === void 0 ? void 0 : state[this.key];

    if (value === undefined) {
      if (this.optional) {
        return [{
          leaf: false,
          segment: undefined,
          nextLocation: location
        }];
      } else {
        return [];
      }
    }

    if (!this.validator(value)) {
      return [];
    } // eslint-disable-next-line @typescript-eslint/no-unused-vars


    const {
      [this.key]: _,
      ...rest
    } = state;
    const nextLocation = { ...location,
      state: rest
    };
    return [{
      leaf: false,
      segment: value,
      nextLocation
    }];
  }

}

exports.StateLocationComposer = StateLocationComposer;