"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLocationNotFoundError = isLocationNotFoundError;
exports.LocationNotFoundError = void 0;

/**
 * Error that current location could not be resolved.
 */
class LocationNotFoundError extends Error {}
/**
 * Returns whether given object is a LocationNotFoundError object.
 */


exports.LocationNotFoundError = LocationNotFoundError;

function isLocationNotFoundError(obj) {
  return obj instanceof LocationNotFoundError;
}