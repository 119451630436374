"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fillOptions = fillOptions;

/**
 * @package
 */
function fillOptions(options) {
  if (!options.root) {
    options.root = {
      pathname: "/",
      state: null
    };
  }
}