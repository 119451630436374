"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Resolver = exports.SingleHash = exports.Root = exports.State = exports.Search = exports.Path = void 0;

var _PathRouteBuilder = require("./builder/PathRouteBuilder");

var _RootRouteBuilder = require("./builder/RootRouteBuilder");

var _RoutePathResolver = require("./builder/RoutePathResolver");

var _SearchRouteBuilder = require("./builder/SearchRouteBuilder");

var _SingleHashRouteBuilder = require("./builder/SingleHashRouteBuilder");

var _StateRouteBuilder = require("./builder/StateRouteBuilder");

/**
 * Initialize a path router.
 */
const Path = _PathRouteBuilder.PathRouteBuilder.init;
/**
 * Initialize a search router.
 */

exports.Path = Path;
const Search = _SearchRouteBuilder.SearchRouteBuilder.init;
/**
 * Initialize a state router.
 */

exports.Search = Search;
const State = _StateRouteBuilder.StateRouteBuilder.init;
/**
 * Initialize a root router.
 */

exports.State = State;
const Root = _RootRouteBuilder.RootRouteBuilder.init;
/**
 * Initialize a single hash router.
 */

exports.Root = Root;
const SingleHash = _SingleHashRouteBuilder.SingleHashRouteBuilder.init;
/**
 * Get a resolver of given router.
 */

exports.SingleHash = SingleHash;
const Resolver = _RoutePathResolver.RoutePathResolver.getFromBuilder;
exports.Resolver = Resolver;