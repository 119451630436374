"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MatchingRouteRecord = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classPrivateFieldSet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldSet"));

var _classPrivateFieldGet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldGet"));

var _RouteRecordBase = require("./RouteRecordBase");

var _validator = new WeakMap();

/**
 * Object for wildcard route in RouteBuilder.
 */
class MatchingRouteRecord extends _RouteRecordBase.RouteRecordBase {
  constructor(parent, matchKey, validator, action) {
    super(parent.getBuilderLink(), action, match => {
      const matchedValue = match[this.key];

      if (!(0, _classPrivateFieldGet2.default)(this, _validator).call(this, matchedValue)) {
        throw new Error("Invariant failure: type of '".concat(matchedValue, "' is wrong"));
      }

      return matchedValue;
    });
    (0, _defineProperty2.default)(this, "key", void 0);

    _validator.set(this, {
      writable: true,
      value: void 0
    });

    (0, _classPrivateFieldSet2.default)(this, _validator, validator);
    this.key = matchKey;
  }

}

exports.MatchingRouteRecord = MatchingRouteRecord;