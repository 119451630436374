"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locationToURL = void 0;

const locationToURL = location => {
  return "".concat(location.pathname).concat(location.search ? !location.search.startsWith("?") ? "?" + location.search : location.search : "").concat(location.hash || "");
};

exports.locationToURL = locationToURL;