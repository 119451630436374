"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouteRecordBase = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classPrivateFieldGet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldGet"));

var _classPrivateFieldSet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldSet"));

var _symbols = require("../symbols");

const defaultRoot = {
  pathname: "/",
  state: null
};
/**
 * Object for each route provided by RouteBuilder.
 * Should implement RouteRecordType.
 */

var _builder = new WeakMap();

class RouteRecordBase {
  /**
   * Action of this route.
   */
  constructor(parentLink, action, segmentGetter) {
    (0, _defineProperty2.default)(this, "action", void 0);
    (0, _defineProperty2.default)(this, _symbols.routeRecordParentKey, void 0);

    _builder.set(this, {
      writable: true,
      value: undefined
    });

    (0, _defineProperty2.default)(this, _symbols.routeRecordSegmentGetterKey, void 0);
    (0, _defineProperty2.default)(this, "attach", void 0);
    this.action = action;
    this[_symbols.routeRecordSegmentGetterKey] = segmentGetter;
    Object.defineProperty(this, _symbols.routeRecordParentKey, {
      value: parentLink
    });
    Object.defineProperty(this, "attach", {
      configurable: true,
      writable: true,

      value(builder) {
        const link = builder.getBuilderLink().getAttachmentRoot();
        (0, _classPrivateFieldSet2.default)(this, _builder, link);
        link.attachToParent(parentLink, segmentGetter);
        return builder;
      }

    });
  }
  /**
   * Get the link attached to this Route.
   */


  getAttachedBuilderLink() {
    return (0, _classPrivateFieldGet2.default)(this, _builder);
  }
  /**
   * Attach given builder as a child of this route.
   */


}

exports.RouteRecordBase = RouteRecordBase;