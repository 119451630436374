"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNavigationBaseLocation = void 0;

var _symbols = require("../../builder/symbols");

const getNavigationBaseLocation = (parentRoute, route) => {
  const ancestorRoutes = parentRoute === null || parentRoute === void 0 ? void 0 : parentRoute.ancestorRoutes;

  const routeTopmostBuilderLink = route[_symbols.routeRecordParentKey].getAttachmentRoot();

  const record = ancestorRoutes === null || ancestorRoutes === void 0 ? void 0 : ancestorRoutes.find(({
    link
  }) => link === routeTopmostBuilderLink);
  return (record === null || record === void 0 ? void 0 : record.location) || (parentRoute === null || parentRoute === void 0 ? void 0 : parentRoute.routeLocation);
};

exports.getNavigationBaseLocation = getNavigationBaseLocation;