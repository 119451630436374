function i18n(lang) {
    var _this = this;
    this.lang = lang;
    this.tran = function (text) {
        if (tranTxt[_this.lang] && tranTxt[_this.lang][text]) {
            return tranTxt[_this.lang][text];
        } else {
            return text;
        }
    };
}
var tranTxt = {
    'zh-cn': {
        'Danmaku is loading': '弹幕加载中',
        Top: '顶部',
        Bottom: '底部',
        Rolling: '滚动',
        'Input danmaku, hit Enter': '输入弹幕\uFF0C回车发送',
        'About author': '关于作者',
        'DPlayer feedback': '播放器意见反馈',
        'About DPlayer': '关于 DPlayer 播放器',
        Loop: '洗脑循环',
        Speed: '速度',
        'Opacity for danmaku': '弹幕透明度',
        Normal: '正常',
        'Please input danmaku content!': '要输入弹幕内容啊喂\uFF01',
        'Set danmaku color': '设置弹幕颜色',
        'Set danmaku type': '设置弹幕类型',
        'Show danmaku': '显示弹幕',
        'Video load failed': '视频加载失败',
        'Danmaku load failed': '弹幕加载失败',
        'Danmaku send failed': '弹幕发送失败',
        'Switching to': '正在切换至',
        'Switched to': '已经切换至',
        Synchronize: '同步',
        Synchronized: '同步的',
        quality: '画质',
        FF: '快进',
        REW: '快退',
        'Unlimited danmaku': '海量弹幕',
        'Send danmaku': '发送弹幕',
        Setting: '设置',
        'Full screen': '全屏',
        'Web full screen': '页面全屏',
        'Picture-in-Picture': '子母画面',
        Send: '发送',
        Screenshot: '截图',
        AirPlay: '无线投屏',
        s: '秒',
        'Show comment': '显示弹幕',
        'Hide comment': '隐藏弹幕',
        'Show subtitle': '显示字幕',
        'Hide subtitle': '隐藏字幕',
        Volume: '音量',
        Live: '直播',
        'Video info': '视频统计信息'
    },
    'zh-tw': {
        'Danmaku is loading': '彈幕載入中',
        Top: '頂部',
        Bottom: '底部',
        Rolling: '滾動',
        'Input danmaku, hit Enter': '輸入彈幕\uFF0CEnter 發送',
        'About author': '關於作者',
        'DPlayer feedback': '播放器意見回饋',
        'About DPlayer': '關於 DPlayer 播放器',
        Loop: '循環播放',
        Speed: '速度',
        'Opacity for danmaku': '彈幕透明度',
        Normal: '正常',
        'Please input danmaku content!': '請輸入彈幕內容啊\uFF01',
        'Set danmaku color': '設定彈幕顏色',
        'Set danmaku type': '設定彈幕類型',
        'Show danmaku': '顯示彈幕',
        'Video load failed': '影片載入失敗',
        'Danmaku load failed': '彈幕載入失敗',
        'Danmaku send failed': '彈幕發送失敗',
        'Switching to': '正在切換至',
        'Switched to': '已經切換至',
        Synchronize: '同步',
        Synchronized: '同步的',
        quality: '畫質',
        FF: '快進',
        REW: '快退',
        'Unlimited danmaku': '巨量彈幕',
        'Send danmaku': '發送彈幕',
        Setting: '設定',
        'Full screen': '全螢幕',
        'Web full screen': '頁面全螢幕',
        'Picture-in-Picture': '子母畫面',
        Send: '發送',
        Screenshot: '截圖',
        AirPlay: '無線投屏',
        s: '秒',
        'Show comment': '顯示彈幕',
        'Hide comment': '隱藏彈幕',
        'Show subtitle': '顯示字幕',
        'Hide subtitle': '隱藏字幕',
        Volume: '音量',
        Live: '直播',
        'Video info': '影片統計訊息'
    },
    'ja-jp': {
        'Danmaku is loading': 'コメントを読み込み中\u2026',
        Top: '上固定',
        Bottom: '下固定',
        Rolling: 'スクロール',
        'Input danmaku, hit Enter': '右のボタンを押すとコメントできます',
        'About author': '作者について',
        'DPlayer feedback': 'フィードバック',
        'About DPlayer': 'DPlayer について',
        Loop: 'リピート再生',
        Speed: '再生速度',
        'Opacity for danmaku': 'コメントの透明度',
        Normal: '標準',
        'Please input danmaku content!': 'コメント内容を入力して下さい\uFF01',
        'Set danmaku color': 'コメントの色',
        'Set danmaku type': 'コメントの位置',
        'Show danmaku': 'コメントを表示',
        'Video load failed': '動画の読み込みに失敗しました\u2026',
        'Danmaku load failed': 'コメントの読み込みに失敗しました\u2026',
        'Danmaku send failed': 'コメントの送信に失敗しました\u2026',
        'Switching to': '下記の画質に切り替えています\u2026',
        'Switched to': '画質を切り替えました',
        Synchronize: '同期する',
        Synchronized: '同期しました',
        quality: '品質',
        FF: '早送り',
        REW: '巻き戻し',
        'Unlimited danmaku': 'コメントを無制限に表示',
        'Send danmaku': 'コメントする',
        Setting: '設定',
        'Full screen': 'フルスクリーン',
        'Web full screen': 'ブラウザフルスクリーン',
        'Picture-in-Picture': 'ピクチャーインピクチャー',
        Send: 'コメントする',
        Screenshot: 'スクリーンショット',
        s: '秒',
        'Show comment': 'コメントを表示する',
        'Hide comment': 'コメントを表示しない',
        'Show subtitle': '字幕を表示する',
        'Hide subtitle': '字幕を表示しない',
        Volume: '音量',
        Live: 'Live',
        'Video info': '動画の統計情報'
    }
};
export default i18n;