"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isRootPath = void 0;

/**
 * Returns whether given location is the root path.
 */
const isRootPath = ({
  pathname
}) => {
  return !pathname || pathname === "/";
};

exports.isRootPath = isRootPath;