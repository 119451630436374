"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SingleHashRouteBuilder = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classPrivateFieldGet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldGet"));

var _classPrivateFieldSet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldSet"));

var _BuilderLink = require("../../core/BuilderLink");

var _validator = require("../../validator");

var _SingleHashLocationComposer = require("../composers/SingleHashLocationComposer");

var _MatchingRouteRecord = require("../RouteRecord/MatchingRouteRecord");

var _SingleRouteAbstractBuilder = require("../SingleRouteAbstractBuilder");

var _link = new WeakMap();

var _route = new WeakMap();

class SingleHashRouteBuilder extends _SingleRouteAbstractBuilder.SingleRouteAbstractBuilder {
  static init(matchKey, options = {}) {
    const optional = options.optional || false;
    const link = new _BuilderLink.BuilderLink({
      composer: new _SingleHashLocationComposer.SingleHashLocationComposer(optional)
    });
    const result = new SingleHashRouteBuilder(link, matchKey, optional);
    return result;
  }

  constructor(link, matchKey, optional) {
    super();
    (0, _defineProperty2.default)(this, "matchKey", void 0);
    (0, _defineProperty2.default)(this, "optional", void 0);

    _link.set(this, {
      writable: true,
      value: void 0
    });

    _route.set(this, {
      writable: true,
      value: void 0
    });

    (0, _classPrivateFieldSet2.default)(this, _link, link);
    this.matchKey = matchKey;
    this.optional = optional;
    (0, _classPrivateFieldSet2.default)(this, _route, new _MatchingRouteRecord.MatchingRouteRecord(this, matchKey, optional ? _validator.isStringOrUndefined : _validator.isString, undefined));
    link.register(this, value => {
      const route = (0, _classPrivateFieldGet2.default)(this, _route);
      return {
        type: "matching",
        value: route,
        link: route.getAttachedBuilderLink(),
        matchKey,
        matchValue: value
      };
    });
  }
  /**
   * Define action for this route and return a new instance of SingleHashRouteBuilder.
   */


  action(action) {
    const result = new SingleHashRouteBuilder((0, _classPrivateFieldGet2.default)(this, _link).inherit(), this.matchKey, this.optional);
    (0, _classPrivateFieldSet2.default)(result, _route, new _MatchingRouteRecord.MatchingRouteRecord(result, this.matchKey, this.optional ? _validator.isStringOrUndefined : _validator.isString, action));
    return result;
  }
  /**
   * Get a route object of this builder.
   */


  getRoute() {
    return (0, _classPrivateFieldGet2.default)(this, _route);
  }

  getBuilderLink() {
    return (0, _classPrivateFieldGet2.default)(this, _link);
  }

}

exports.SingleHashRouteBuilder = SingleHashRouteBuilder;