"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Redirect = void 0;

var _react = require("react");

var _useNavigate = require("../hooks/useNavigate");

/**
 * Redirects to given route.
 */
const Redirect = ({
  route,
  match
}) => {
  const navigate = (0, _useNavigate.useNavigate)();
  (0, _react.useLayoutEffect)(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigate.replace(route, match);
  });
  return null;
};

exports.Redirect = Redirect;