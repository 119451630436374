"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PathRouteRecord = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _RouteRecordBase = require("./RouteRecordBase");

/**
 * Object for each route provided by PathRouteBuilder.
 */
class PathRouteRecord extends _RouteRecordBase.RouteRecordBase {
  /**
   * Key of this route.
   */
  constructor(parent, key, action) {
    super(parent.getBuilderLink(), action, () => key);
    (0, _defineProperty2.default)(this, "key", void 0);
    this.key = key;
  }

}

exports.PathRouteRecord = PathRouteRecord;