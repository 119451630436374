"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSegmentDecomposer = createSegmentDecomposer;
exports.resolveChain = resolveChain;

/**
 * Create a segment decomposer function for given BuilderLink and SegmentResolver.
 */
function createSegmentDecomposer(link, resolveSegment) {
  const {
    composer
  } = link;
  return location => {
    const decomposed = composer.decompose(location);
    return decomposed.flatMap(({
      segment,
      nextLocation
    }) => {
      const resolved = resolveSegment(segment, nextLocation);

      if (resolved === undefined) {
        return [];
      }

      return [{ ...resolved,
        segment,
        nextLocation
      }];
    });
  };
}
/**
 * Resolve location from given link and location
 * @package
 */


function resolveChain(link, location, currentLocation) {
  var _link$segmentDecompos;

  const resolvedSegments = (_link$segmentDecompos = link.segmentDecomposer) === null || _link$segmentDecompos === void 0 ? void 0 : _link$segmentDecompos.call(link, location);

  if (resolvedSegments === undefined) {
    return [];
  }

  return resolvedSegments.flatMap(resolved => {
    const nextCurrentLocation = link.composer.compose(currentLocation, resolved.segment);
    const match = resolved.type === "normal" ? {} : {
      [resolved.matchKey]: resolved.matchValue
    };
    const childLink = resolved.link;

    if (childLink === undefined) {
      return [{
        route: resolved.value,
        match,
        remainingLocation: resolved.nextLocation,
        currentLocation: nextCurrentLocation
      }];
    }

    const result = resolveChain(childLink, resolved.nextLocation, nextCurrentLocation);

    if (result.length === 0 && childLink.composer.isLeaf(resolved.nextLocation)) {
      return [{
        route: resolved.value,
        match,
        remainingLocation: resolved.nextLocation,
        currentLocation: nextCurrentLocation
      }];
    }

    switch (resolved.type) {
      case "normal":
        {
          return result;
        }

      case "matching":
        {
          const key = resolved.matchKey;
          const matchedValue = resolved.matchValue;
          return result.map(res => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.match[key] = matchedValue;
            return res;
          });
        }
    }
  });
}