"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PathRouteBuilder = void 0;

var _classPrivateFieldGet3 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldGet"));

var _classPrivateFieldSet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldSet"));

var _BuilderLink = require("../../core/BuilderLink");

var _validator = require("../../validator");

var _PathLocationComposer = require("../composers/PathLocationComposer");

var _RouteRecord = require("../RouteRecord");

var _MatchingRouteRecord = require("../RouteRecord/MatchingRouteRecord");

var _isRootPath = require("./isRootPath");

var _link = new WeakMap();

var _routes = new WeakMap();

var _wildcardRoute = new WeakMap();

var _exactRoute = new WeakMap();

/**
 * Builder to define routes using pathname.
 */
class PathRouteBuilder {
  static init() {
    return new PathRouteBuilder(new _BuilderLink.BuilderLink({
      composer: new _PathLocationComposer.PathLocationComposer()
    }));
  }

  constructor(link) {
    _link.set(this, {
      writable: true,
      value: void 0
    });

    _routes.set(this, {
      writable: true,
      value: Object.create(null)
    });

    _wildcardRoute.set(this, {
      writable: true,
      value: undefined
    });

    _exactRoute.set(this, {
      writable: true,
      value: undefined
    });

    (0, _classPrivateFieldSet2.default)(this, _link, link);
    link.register(this, (value, remainingLocation) => {
      if (value === undefined) {
        // this is for exact route, so we check whether the route is exact
        if (!(0, _isRootPath.isRootPath)(remainingLocation)) {
          return undefined;
        }

        const exactRoute = (0, _classPrivateFieldGet3.default)(this, _exactRoute);
        return exactRoute && {
          type: "normal",
          value: exactRoute,
          link: exactRoute.getAttachedBuilderLink()
        };
      }

      const route = (0, _classPrivateFieldGet3.default)(this, _routes)[value];

      if (route !== undefined) {
        return {
          type: "normal",
          value: route,
          link: route.getAttachedBuilderLink()
        };
      }

      const wildcardRoute = (0, _classPrivateFieldGet3.default)(this, _wildcardRoute);

      if (wildcardRoute !== undefined) {
        return {
          type: "matching",
          value: wildcardRoute.route,
          link: wildcardRoute.route.getAttachedBuilderLink(),
          matchKey: wildcardRoute.matchKey,
          matchValue: value
        };
      }

      return undefined;
    });
  }
  /**
   * Add multiple routes at once and return a new Path route builder.
   */


  routes(defs) {
    const result = new PathRouteBuilder((0, _classPrivateFieldGet3.default)(this, _link).inherit());
    const routes = (0, _classPrivateFieldGet3.default)(result, _routes);
    Object.assign(routes, (0, _classPrivateFieldGet3.default)(this, _routes));

    for (const key of Object.getOwnPropertyNames(defs)) {
      routes[key] = new _RouteRecord.PathRouteRecord(result, key, defs[key].action);
    }

    (0, _classPrivateFieldSet2.default)(result, _wildcardRoute, (0, _classPrivateFieldGet3.default)(this, _wildcardRoute));
    (0, _classPrivateFieldSet2.default)(result, _exactRoute, (0, _classPrivateFieldGet3.default)(this, _exactRoute));
    return result;
  }
  /**
   * Add a route and return a new Path route builder.
   */


  route(key, callback) {
    const result = new PathRouteBuilder((0, _classPrivateFieldGet3.default)(this, _link).inherit());
    const routes = (0, _classPrivateFieldGet3.default)(result, _routes);
    Object.assign(routes, (0, _classPrivateFieldGet3.default)(this, _routes)); // collect information of route by calling callback.

    let action;
    let attachedBuilder;

    if (callback) {
      const intr = {
        action(a) {
          action = a;
          return this;
        },

        attach(builder) {
          attachedBuilder = builder; // eslint-disable-next-line @typescript-eslint/no-explicit-any

          return builder;
        }

      };
      callback(intr);
    }

    const record = new _RouteRecord.PathRouteRecord(result, key, action);

    if (attachedBuilder !== undefined) {
      record.attach(attachedBuilder);
    }

    routes[key] = record;
    (0, _classPrivateFieldSet2.default)(result, _wildcardRoute, (0, _classPrivateFieldGet3.default)(this, _wildcardRoute));
    (0, _classPrivateFieldSet2.default)(result, _exactRoute, (0, _classPrivateFieldGet3.default)(this, _exactRoute));
    return result;
  }
  /**
   * Add a catch-all route and return a new Path route builder.
   */


  any(key, routeDefinition) {
    const result = new PathRouteBuilder((0, _classPrivateFieldGet3.default)(this, _link).inherit());
    (0, _classPrivateFieldSet2.default)(result, _routes, (0, _classPrivateFieldGet3.default)(this, _routes));
    (0, _classPrivateFieldSet2.default)(result, _wildcardRoute, {
      matchKey: key,
      route: new _MatchingRouteRecord.MatchingRouteRecord(result, // TypeScript requires this `as` but this should be true because Key extends string.
      key, _validator.isString, routeDefinition === null || routeDefinition === void 0 ? void 0 : routeDefinition.action)
    });
    return result;
  }
  /**
   * Add an exact route and return a new Path route builder.
   */


  exact(routeDefinition) {
    const result = new PathRouteBuilder((0, _classPrivateFieldGet3.default)(this, _link).inherit());
    (0, _classPrivateFieldSet2.default)(result, _routes, (0, _classPrivateFieldGet3.default)(this, _routes));
    (0, _classPrivateFieldSet2.default)(result, _wildcardRoute, (0, _classPrivateFieldGet3.default)(this, _wildcardRoute));
    (0, _classPrivateFieldSet2.default)(result, _exactRoute, new _RouteRecord.PathRouteRecord(result, undefined, routeDefinition.action));
    return result;
  }

  getRoutes() {
    const routes = (0, _classPrivateFieldGet3.default)(this, _routes);
    return routes;
  }
  /**
   * Shorthand for 'getRoutes()'
   */


  get _() {
    return this.getRoutes();
  }
  /**
   * Route record of the any route.
   */


  get anyRoute() {
    var _classPrivateFieldGet2;

    return (_classPrivateFieldGet2 = (0, _classPrivateFieldGet3.default)(this, _wildcardRoute)) === null || _classPrivateFieldGet2 === void 0 ? void 0 : _classPrivateFieldGet2.route;
  }
  /**
   * Route record of the exact route.
   */


  get exactRoute() {
    return (0, _classPrivateFieldGet3.default)(this, _exactRoute);
  }

  getBuilderLink() {
    return (0, _classPrivateFieldGet3.default)(this, _link);
  }

}

exports.PathRouteBuilder = PathRouteBuilder;