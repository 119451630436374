"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StateRouteBuilder = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classPrivateFieldGet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldGet"));

var _classPrivateFieldSet2 = _interopRequireDefault(require("@babel/runtime/helpers/classPrivateFieldSet"));

var _BuilderLink = require("../../core/BuilderLink");

var _validator2 = require("../../validator");

var _StateLocationComposer = require("../composers/StateLocationComposer");

var _MatchingRouteRecord = require("../RouteRecord/MatchingRouteRecord");

var _SingleRouteAbstractBuilder = require("../SingleRouteAbstractBuilder");

var _link = new WeakMap();

var _validator = new WeakMap();

var _route = new WeakMap();

class StateRouteBuilder extends _SingleRouteAbstractBuilder.SingleRouteAbstractBuilder {
  static init(matchKey, validator, options = {}) {
    var _options$stateKey;

    const stateKey = (_options$stateKey = options.stateKey) !== null && _options$stateKey !== void 0 ? _options$stateKey : matchKey;
    const optional = options.optional || false;
    const usedValidator = optional ? (0, _validator2.isUndefinedOr)(validator) : validator;
    const link = new _BuilderLink.BuilderLink({
      composer: new _StateLocationComposer.StateLocationComposer(stateKey, usedValidator, optional)
    });
    const result = new StateRouteBuilder(link, matchKey, usedValidator);
    return result;
  }

  constructor(link, matchKey, validator) {
    super();
    (0, _defineProperty2.default)(this, "matchKey", void 0);

    _link.set(this, {
      writable: true,
      value: void 0
    });

    _validator.set(this, {
      writable: true,
      value: void 0
    });

    _route.set(this, {
      writable: true,
      value: void 0
    });

    (0, _classPrivateFieldSet2.default)(this, _link, link);
    this.matchKey = matchKey;
    (0, _classPrivateFieldSet2.default)(this, _validator, validator);
    (0, _classPrivateFieldSet2.default)(this, _route, new _MatchingRouteRecord.MatchingRouteRecord(this, matchKey, validator, undefined));
    link.register(this, value => {
      const route = (0, _classPrivateFieldGet2.default)(this, _route);
      return {
        type: "matching",
        value: route,
        link: route.getAttachedBuilderLink(),
        matchKey: matchKey,
        matchValue: value
      };
    });
  }
  /**
   * Define action for this route and return a new instance of StateRouteBuilder.
   */


  action(action) {
    const validator = (0, _classPrivateFieldGet2.default)(this, _validator);
    const result = new StateRouteBuilder((0, _classPrivateFieldGet2.default)(this, _link).inherit(), this.matchKey, validator);
    (0, _classPrivateFieldSet2.default)(result, _route, new _MatchingRouteRecord.MatchingRouteRecord(result, this.matchKey, validator, action));
    return result;
  }

  getRoute() {
    return (0, _classPrivateFieldGet2.default)(this, _route);
  }

  getBuilderLink() {
    return (0, _classPrivateFieldGet2.default)(this, _link);
  }

}

exports.StateRouteBuilder = StateRouteBuilder;