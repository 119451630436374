"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.routeRecordSegmentGetterKey = exports.routeRecordParentKey = void 0;

/**
 * Name of the parent builder ot route record.
 */
const routeRecordParentKey = Symbol("routeRecordParentKey");
/**
 * Name of segment getter of route record.
 */

exports.routeRecordParentKey = routeRecordParentKey;
const routeRecordSegmentGetterKey = Symbol("routeRecordSegmentGetterKey");
exports.routeRecordSegmentGetterKey = routeRecordSegmentGetterKey;